/* 
import "firebase/auth"
import React from 'react'
import AuthProvider from '.src/context/auth'

export const wrapRootElement =({elemnt}) => (
  <AuthProvider>{element</AuthProvider>)
*/



exports.onInitialClientRender = () => {
  const theme = typeof window !== 'undefined' && localStorage.getItem('theme')

  if (typeof window !== 'undefined' && theme === 'dark') {
    localStorage.setItem('theme', 'dark')
    const darkToggle = document.getElementById('dark-mode-button');
    if (!darkToggle) return
    darkToggle.textContent = '☀️'
    const head = document.getElementsByTagName('head')[0]
    const link = document.createElement('link')
    link.rel = 'stylesheet'
    link.id = 'dark-mode'
    link.href = '../dark.css'
    head.appendChild(link)
  }
}
